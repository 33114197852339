import React, {useEffect, useRef, useState} from "react"
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem,
  Row
} from 'reactstrap'
import * as turf from "@turf/turf";
import 'react-vertical-timeline-component/style.min.css';
import {graphql} from "gatsby";

import 'leaflet/dist/leaflet.css'
import {TileLayer, Marker, Popup, Polyline} from 'react-leaflet'
import polyUtil from 'polyline-encoded';

import {LangContext} from "../components/lurraldebusWrapper";
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from '../components/pageTitle'
import Map from "../components/map"

import MapMarkerParadaBigIcon from "../components/mapMarkerParadaBigIcon"
import MapMarkerCurrentBigIcon from "../components/mapMarkerCurrentBigIcon"

import {VerticalTimeline, VerticalTimelineElement} from "react-vertical-timeline-component";
import {FaWalking} from "react-icons/fa";
import useCookie from "../components/useCookie";

const centroBarakaldo = [43.27712187931624, -2.9845749134823794];

const ParadaMasCercanaPage = ({data}) => {

  const [cookie, updateCookie] = useCookie('active-lang');
  const [activeLang, setActiveLang] = useState(['esp', 'eus'].indexOf(cookie) > -1 ? cookie : 'eus');

  const [direccionQueryOrigen, setDireccionQueryOrigen] = useState("");
  const [candidatosOrigen, setCandidatosOrigen] = useState(null);
  const [origenSeleccionado, setOrigenSeleccionado] = useState(null);
  const [paradaSeleccionada, setParadaSeleccionada] = useState(null);
  const [route, setRoute] = useState(null);
  const [itinerario, setItinerario] = useState(null);
  const [nearestParadas, setNearestParadas] = useState([]);
  const [mapBounds, setMapBounds] = useState([]);
  const [centroMapa, setCentroMapa] = useState(centroBarakaldo);

  const [processingRuta, setProcessingRuta] = useState(false);
  const [processingOrigen, setProcessingOrigen] = useState(false);

  const inputOrigenRef = useRef(null)

  const redOptions = { color: '#f69f06', smoothFactor: 0.1 }
  const greenOptions = { color: '#129540', smoothFactor: 0.1 }

  let paradas = [];
  data.lineas.edges.forEach((linea) => {
    linea.node.rutas.forEach((ruta) => {
      const lineaParadas = ruta.paradas.map((parada) => {
        return {
          coord: parada.coord,
          label: parada.label,
          nombre: parada.nombre,
          linea: linea.node.nombre,
          direccion: ruta.nombre
        }
      });
      paradas = paradas.concat(lineaParadas);
    })
  });

  useEffect(() => {
    setRoute(null);
    setItinerario(null);
    if (origenSeleccionado !== null && inputOrigenRef !== null) {
      if (origenSeleccionado.mapMarker) {
        inputOrigenRef.current.value = activeLang === "eus" ? data.paradaCercana_eus.ubicacionMapa : data.paradaCercana.ubicacionMapa
      } else {
        inputOrigenRef.current.value = origenSeleccionado.place_name.split(",").slice(0, 2).join(",")
      }
      setCandidatosOrigen(null);
    }

    if (origenSeleccionado !== null) {
      setCentroMapa(origenSeleccionado.center.slice().reverse())
      const from = turf.point(origenSeleccionado.center);
      paradas.sort((parada1, parada2) => {
        const toParada1 = turf.point([parada1.coord.lon, parada1.coord.lat]);
        const toParada2 = turf.point([parada2.coord.lon, parada2.coord.lat]);
        return turf.distance(from, toParada1) - turf.distance(from, toParada2);
      })

      const nearestParadas = paradas.slice(0, 6);
      setNearestParadas(nearestParadas);
    }
  }, [origenSeleccionado])

  useEffect(() => {
    if (route === null || origenSeleccionado === null) return;
    const stepColor = "#f69f06";
    const iconColor = "#f69f06";
    const origenNombre = origenSeleccionado.mapMarker ? activeLang === "eus" ? data.paradaCercana_eus.ubicacionMapa : data.paradaCercana.ubicacionMapa : origenSeleccionado.place_name.split(",").slice(0, 2).join(",")
    const stepIcon = <FaWalking/>
    const stepTitle = <span>{activeLang === "eus" ? data.paradaCercana_eus.dirigeteA : data.paradaCercana.dirigeteA} <strong>{paradaSeleccionada.nombre}</strong></span>
    const stepSubtitle = <span><strong>{Math.floor(route.distancia)}</strong> metros</span>;
    const stepContent = (
      <div className={"d-flex flex-column justify-content-center align-items-start pt-2"}>
        <span>De: {origenNombre}</span>
        <span>A: {paradaSeleccionada.nombre}</span>
      </div>
    )

    const _ruta = polyUtil.decode(route.ruta);

    const _itinerario ={
      ruta: _ruta,
      element:  (
      <VerticalTimelineElement
        contentStyle={{ border: `3px solid ${stepColor}`, boxShadow: 'none', marginBottom: '-1em' }}
        contentArrowStyle={{ borderRight: `7px solid ${stepColor}` }}
        iconStyle={{ background: iconColor, color: '#fff', boxShadow: 'none' }}
        icon={stepIcon}
        date={`${route.tiempo > 0 ? route.tiempo : 1} ${activeLang === "eus" ? data.paradaCercana_eus.minutos : data.paradaCercana.minutos}`}
      >
        <span style={{fontSize: '18px'}} className="vertical-timeline-element-title">{stepTitle}</span>
        {stepSubtitle && (<p>{stepSubtitle}</p>)}
        {stepContent}
      </VerticalTimelineElement>
    )
  }
    setItinerario(_itinerario);
  }, [route])

  /*
  useEffect(() => {
    const _mapBounds = nearestParadas.map((parada) => [parada.coord.lat, parada.coord.lon])
    setMapBounds(_mapBounds)
  }, [nearestParadas])
  */

  const markOrigen = (latLng) => {
    setOrigenSeleccionado({
      center: [latLng.lng, latLng.lat],
      place_name: `${latLng.lat}, ${latLng.lng}`,
      mapMarker: true
    })
  }

  const buscarDireccion = (direccion, setter, setProcessing) => {
    const xhr = new XMLHttpRequest()
    xhr.open("GET", `https://buruntzaldeabus.eus/getGeoCandidatesv2.php?q=${direccion}`)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      } else {
        setProcessing(false)
      }
      if (xhr.status === 200) {
        try {
          const candidatesRaw = JSON.parse(xhr.responseText)
          const candidates = Object.values(candidatesRaw).filter((candidate) => {
            return true;
          })
          setter(candidates);
        } catch (e) {
          console.log(e);
        }
      } else {
        // TODO handle error
      }
    };
    setProcessing(true)
    xhr.send()
  }

  const buscarRuta = (parada) => {
    if (!processingRuta) {
      setParadaSeleccionada(parada);
      const xhr = new XMLHttpRequest()
      const o = origenSeleccionado.center.slice().reverse().join(",");
      const d = [parada.coord.lat, parada.coord.lon].join(",");
      xhr.open("GET", `https://buruntzaldeabus.eus/kbus-grafos/getRouteToParada.php?o=${o}&d=${d}`)
      xhr.setRequestHeader("Accept", "application/json")
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) {
          return;
        } else {
          setProcessingRuta(false);
        }
        if (xhr.status === 200) {
          try {
            const _route = JSON.parse(xhr.responseText)
            setRoute(_route);
          } catch (e) {
            console.log(e);
          }
        } else {
          // TODO handle error
        }
      };
      setProcessingRuta(true);
      xhr.send()
    }
  }

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'paradaCercana', 'titulo')} />

          <Container className="pt-5 mb-5">
            <PageTitle title={context.translate(data, 'paradaCercana', 'titulo')}/>
            <Row className={"py-3"}>
              <Col xs={12} lg={{size:8, offset:2}} xl={{size: 6, offset:3}}>
                <Label>{context.translate(data, 'paradaCercana', 'tuLocalizacion')}:</Label>
                <InputGroup>
                  <Input innerRef={inputOrigenRef} placeholder={context.translate(data, 'paradaCercana', 'tuLocalizacionPlaceholder')}
                         onChange={(e) => {setDireccionQueryOrigen(e.target.value)}}
                         onKeyPress={(e) => {if (e.charCode === 13) {setOrigenSeleccionado(null); buscarDireccion(direccionQueryOrigen, setCandidatosOrigen, setProcessingOrigen)}}}
                  />
                  <InputGroupAddon addonType="append">
                    <Button color="danger" disabled={direccionQueryOrigen.length < 4 || processingOrigen}
                            onClick={() => {setOrigenSeleccionado(null); buscarDireccion(direccionQueryOrigen, setCandidatosOrigen, setProcessingOrigen)}}>
                      {context.translate(data, 'paradaCercana', 'buscar')}
                    </Button>
                  </InputGroupAddon>
                </InputGroup>

                <div className={"kbus-animation-1 mt-3"} style={
                  {
                    maxHeight: `${candidatosOrigen !== null ? '1000px' : '0px'}`,
                    opacity: `${candidatosOrigen !== null ? '1' : '0'}`,
                    overflow: 'hidden'
                  }
                }>
                    <Label>{context.translate(data, 'paradaCercana', 'resultados')}:</Label>
                    {candidatosOrigen !== null && candidatosOrigen.length > 0 && (
                      <ListGroup>
                      {candidatosOrigen.map((candidato, index) => {
                        const selected = origenSeleccionado !== null ? candidato.id === origenSeleccionado.id : false;
                        return (
                          <ListGroupItem key={index}
                                         className={`${selected ? 'kbus-parada-selected' : ''} kbus-resultados-ruta d-flex flex-row justify-content-between align-items-center py-1 px-3`}
                                         onClick={() => {setOrigenSeleccionado(candidato)}}
                          >
                            <span className="kbus-parada-linea-font">{candidato.place_name.split(",")[0]}</span>
                            <div className="d-flex flex-column align-items-end">
                              <span className="kbus-parada-linea-font py-0 text-capitalize">{candidato.place_name.split(",")[1].split(" ").slice(2).join(" ").toLowerCase()}</span>
                              <span className={`${selected ? '' : 'text-muted'} kbus-parada-linea-sub-font py-0`}>{candidato.place_name.split(",")[1].split(" ")[1]}</span>
                            </div>
                          </ListGroupItem>
                        )
                      })}
                      </ListGroup>
                    )}
                    {candidatosOrigen !== null && candidatosOrigen.length === 0 && (
                      <div className={"d-flex justify-content-center align-items-center"}>
                        <span className={"text-muted text-center"}>{context.translate(data, 'paradaCercana', 'noResultados')}</span>
                      </div>
                    )}
                </div>
              </Col>
            </Row>

            {/*
            <Row className={"py-3"}>
              <Col xs={12} lg={{size:8, offset:2}} xl={{size: 6, offset:3}} className="d-flex justify-content-center align-items-center">
                {processingRuta && <Spinner color={"danger"}/>}
              </Col>
            </Row>
            */}
          </Container>

          <Container className={"pt-2 mb-5"}>
            <Row className={"py-3"}>
              <Col xs={12} style={{height: '500px'}}>
                <Map centroMapa={centroMapa ?? centroBarakaldo} zoomMapa={13} styleMapa={{height: '100%'}} mapaBounds={mapBounds} locatorFn={markOrigen}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {itinerario && (<Polyline pathOptions={greenOptions} positions={itinerario.ruta} />)}

                  {origenSeleccionado && (
                    <Marker position={origenSeleccionado.center.slice().reverse()} icon={MapMarkerCurrentBigIcon()}/>
                  )}

                  {nearestParadas.map((parada, index) => (
                      <Marker key={index}
                              position={[parada.coord.lat, parada.coord.lon]}
                              icon={MapMarkerParadaBigIcon()}
                              eventHandlers={{
                                click: (e) => {
                                  buscarRuta(parada)
                                },
                              }}
                              >
                        <Popup className="kbus-popup-transparent">
                            <span className="font-weight-bold">{parada.nombre}</span>
                            <br/>
                            <span className={"text-capitalize"}>{parada.linea.toLowerCase()}</span>
                        </Popup>
                      </Marker>
                    ))
                  }

                </Map>
              </Col>
            </Row>

            {itinerario && (
              <Row className={"py-3"}>
                <Col xs={{size: 12, offset:0}} md={{size:8, offset:2}} lg={{size:6, offset:3}}>
                  <VerticalTimeline animate={false} layout={'1-column-left'}>
                    {itinerario.element}
                  </VerticalTimeline>
                </Col>
              </Row>
            )}

          </Container>

        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default ParadaMasCercanaPage

export const pageQuery = graphql`  
  query {
    paradaCercana: strapiParadaCercana {
      titulo
      tuLocalizacion
      tuLocalizacionPlaceholder
      ubicacionMapa
      dirigeteA
      minutos
      resultados
      noResultados
      buscar
    }
    paradaCercana_eus: strapiParadaCercanaEus {
      titulo
      tuLocalizacion
      tuLocalizacionPlaceholder
      ubicacionMapa
      dirigeteA
      minutos
      resultados
      noResultados
      buscar
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`
